import { Navbar } from "components/modules/Navigation/Navbar/Navbar";
import type { GetStaticProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useEffect, useState, type ReactNode } from "react";
import { commonLanguageFiles } from "client/config";
import { LanguageProvider } from "client/contexts/LanguageProvider";
import { Translate } from "components/common/Translate/Translate";
import { Tools } from "components/modules/Layout/Tools/Tools";
import { Footer, type LanguageProps } from "components/modules/Layout/Footer/Footer";
import Link from "next/link";
import { useRouter } from "next/router";
import translatedRouteSlugs from "translatedRouteSlugs";

const Page = () => {
    let { pathname: _pathname, asPath, locale } = useRouter();
    const [pathname, setPathname] = useState(_pathname);

    useEffect(() => {
        let pathname = asPath;
        let decodedPathName = pathname;
        try {
            decodedPathName = decodeURI(pathname);
        } catch (e) {}
        let matchedPathname = "";

        (Object.keys(translatedRouteSlugs) as Array<keyof typeof translatedRouteSlugs>).forEach((slug) => {
            if (matchedPathname) return;
            (Object.keys(translatedRouteSlugs[slug]) as Array<keyof (typeof translatedRouteSlugs)[typeof slug]>)
                .filter((localeKey) => localeKey === locale)
                .forEach((localeKey) => {
                    if (matchedPathname) return;
                    if (matchUrl(`/${translatedRouteSlugs[slug][localeKey]}`, decodedPathName)) {
                        const url = `/${locale}/${translatedRouteSlugs[slug]["en"]}`;
                        let finalUrl = "";
                        const urlChunks = url.split("/");
                        const requestPathChunks = `/${locale}${pathname}`.split("/");

                        urlChunks.forEach((chunk, index) => {
                            if (!chunk) return;
                            if (chunk[0] === ":") {
                                finalUrl += `/${requestPathChunks[index]}`;
                            } else {
                                finalUrl += `/${chunk}`;
                            }
                        });
                        matchedPathname = finalUrl;
                    }
                });
        });
        pathname = matchedPathname || pathname;
        setPathname(pathname);
    }, [asPath]);

    if (pathname.includes("resize/editor") || pathname.includes("resize-jpeg/editor")) {
        const taskId = pathname.split("/").at(-1) || "";
        return (
            <PageContainer url={"/resize/editor/:taskId"} urlSlugVariables={{ taskId }}>
                <SingleImageResizerEditorPageError />
            </PageContainer>
        );
    } else if (pathname.includes("bulk-resize") && pathname.includes("download")) {
        const taskId = pathname.split("/").at(-1) || "";
        return (
            <PageContainer url={"/bulk-resize/download/:taskId"} urlSlugVariables={{ taskId }}>
                <BulkImageResizerEditorPageError />
            </PageContainer>
        );
    } else if (pathname.includes("resize/download") || pathname.includes("resize-jpeg/download")) {
        const jobId = pathname.split("/").at(-1) || "";
        return (
            <PageContainer url={"/resize/download/:jobId"} urlSlugVariables={{ jobId }}>
                <SingleImageResizerDownloadPageError />
            </PageContainer>
        );
    }
    return (
        <div className="w-full text-black max-w-1440 page-shadow dark:text-white">
            <div>
                <Navbar url={"/"} />
                <div className="py-8 mx-auto text-3xl font-semibold text-center">
                    <Translate keyName="title" />
                </div>
            </div>
        </div>
    );
};

interface IPageContainer {
    children: ReactNode;
    url: LanguageProps["url"];
    urlSlugVariables?: LanguageProps["urlSlugVariables"];
}

const PageContainer = ({ children, url, urlSlugVariables }: IPageContainer) => (
    <div className="w-full text-black max-w-1440 dark:text-white dark:shadow-black dark:bg-darkSurface-100">
        <div>
            <Navbar url={url} urlSlugVariables={urlSlugVariables} />
            {children}
            <Tools />
            <Footer url={url} urlSlugVariables={urlSlugVariables} />
        </div>
    </div>
);

const LanguageWrapper = () => {
    return (
        <LanguageProvider files={files}>
            <Page />
        </LanguageProvider>
    );
};

export default LanguageWrapper;

const files = ["error", ...commonLanguageFiles];

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    return {
        props: {
            ...(await serverSideTranslations(locale ?? "", files)),
        },
    };
};

const SingleImageResizerDownloadPageError = () => {
    const { locale } = useRouter();
    return (
        <>
            <div className="mx-2 sm:mx-6">
                <div className="flex items-center justify-center flex-grow gap-2 mt-8 capitalize">
                    <div className="text-3xl font-semibold" data-testid="download-page-title">
                        <Translate keyName="title" />
                    </div>
                </div>
                <div className="text-center text-[22px] my-4" data-testid="download-page-sub-title">
                    <Translate
                        keyName="expired-image-text"
                        variables={{
                            hours: 4,
                        }}
                        linkRenderer={(label) => (
                            <Link
                                href={locale === "en" || !locale ? "/" : `/${locale}`}
                                className="font-semibold underline cursor-pointer"
                            >
                                {label}
                            </Link>
                        )}
                    />
                </div>
            </div>
        </>
    );
};

const SingleImageResizerEditorPageError = () => {
    const { locale } = useRouter();
    return (
        <>
            <div className="mx-2 sm:mx-6">
                <div className="flex items-center justify-center flex-grow gap-2 mt-8 capitalize">
                    <div className="text-3xl font-semibold" data-testid="download-page-title">
                        <Translate keyName="title" />
                    </div>
                </div>
                <div className="text-center text-[22px] my-4" data-testid="download-page-sub-title">
                    <Translate
                        keyName="expired-image-text"
                        variables={{
                            hours: 4,
                        }}
                        linkRenderer={(label) => (
                            <Link
                                href={locale === "en" || !locale ? "/" : `/${locale}`}
                                className="font-semibold underline cursor-pointer"
                            >
                                {label}
                            </Link>
                        )}
                    />
                </div>
            </div>
        </>
    );
};

const BulkImageResizerEditorPageError = () => {
    const { locale } = useRouter();
    return (
        <>
            <div className="mx-2 sm:mx-6">
                <div className="flex items-center justify-center flex-grow gap-2 mt-8 capitalize">
                    <div className="text-3xl font-semibold" data-testid="download-page-title">
                        <Translate keyName="title" />
                    </div>
                </div>
                <div className="text-center text-[22px] my-4" data-testid="download-page-sub-title">
                    <Translate
                        keyName="expired-image-text"
                        variables={{
                            hours: 4,
                        }}
                        linkRenderer={(label) => (
                            <Link
                                href={
                                    locale === "en" || !locale
                                        ? `/${translatedRouteSlugs["/bulk-resize"]["en"]}`
                                        : `${`/${locale}/${(translatedRouteSlugs["/bulk-resize"] as any)[locale]}`}`
                                }
                                className="font-semibold underline cursor-pointer"
                            >
                                {label}
                            </Link>
                        )}
                    />
                </div>
            </div>
        </>
    );
};

const matchUrl = (pathKey: string, requestPath: string): boolean => {
    const pathKeyChunks = pathKey.split("/");
    const requestPathChunks = requestPath.split("/");

    return requestPathChunks.every((chunk, index) => {
        const pathChunk = pathKeyChunks[index];
        if (pathChunk && pathChunk[0] === ":") return true;
        return chunk === pathKeyChunks[index];
    });
};
